import * as React from 'react';
import { useState } from 'react';
import './dynamic-cases.scss';
import { graphql, useStaticQuery } from 'gatsby';
import classNames from 'classnames';

function ChangeCase({ title, content, expanded, video, setExpandedVideo }) {
    return <div className={classNames('change-case', { expanded })}>
        <div className="case-heading" onClick={() => setExpandedVideo(video)}>{title}</div>
        <div className="case-content" dangerouslySetInnerHTML={{ __html: content }} />
    </div>
}

export default function DynamicCases() {
    const data = useStaticQuery(graphql`
        query DynamicCasesQuery {
            allDynamiccases {
                nodes {
                    title
                    content
                    video
                }
            }
        }
    `).allDynamiccases.nodes;

    const [expandedVideo, setExpandedVideo] = useState(data[0].video);

    return <section className="dynamic-cases" id="dynamic-cases">
        <div className="wrapper">
            <div className="sidebar">
                <h2>See the Change.</h2>
                {data.map(item => <ChangeCase title={item.title}
                                              content={item.content}
                                              expanded={item.video === expandedVideo}
                                              video={item.video}
                                              setExpandedVideo={setExpandedVideo}
                                              key={item.video}/>)}
            </div>
            <div className="video">
                <video controls autoPlay playsInline loop
                       muted
                       key={expandedVideo}>
                    <source src={`/videos/${expandedVideo}`} type="video/mp4"/>
                </video>
            </div>
        </div>
    </section>
}