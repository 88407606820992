import { MdAssignment, MdFilterHdr, MdGroup, MdPsychology, MdPublic, MdUpdate } from 'react-icons/all';
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import './hero.scss';
import UIButton from '../controls/UIButton';
import classNames from 'classnames';

interface CoverClass {
    key: string;
    title: string;
}

const coverClasses: CoverClass[] = [
    {
        key: 'water',
        title: 'Water'
    },
    {
        key: 'trees',
        title: 'Trees'
    },
    {
        key: 'grass',
        title: 'Grass'
    },
    {
        key: 'crops',
        title: 'Crops'
    },
    {
        key: 'shrub',
        title: 'Shrub & Scrub'
    },
    {
        key: 'vegetation',
        title: 'Flooded Vegetation'
    },
    {
        key: 'area',
        title: 'Built-Up Area'
    },
    {
        key: 'ground',
        title: 'Bare Ground'
    },
    {
        key: 'snow',
        title: 'Snow & Ice'
    },
]

export default function Hero() {
    const [coverIndex, setCoverIndex] = useState(0);
    const coverKey = coverClasses[coverIndex].key;

    // Preload images
    useEffect(() => {
        coverClasses.forEach(cover => {
            const img = new Image();
            img.src = `/images/land-cover-hero/land_${cover.key}.jpg`;
        });
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            if (coverIndex === coverClasses.length - 1) {
                setCoverIndex(0);
            } else {
                setCoverIndex(coverIndex + 1);
            }
        }, 3000);

        return (() => {
            clearInterval(interval);
        })
    }, [coverIndex]);

    const onExploreClick = useCallback(() => {
        navigate('/explore');
    }, []);

    const onDiscoverClick = useCallback(() => {
        document.getElementById('dynamic-cases').scrollIntoView({ behavior: 'smooth' });
    }, []);

    const onReadPaperClick = useCallback(() => {
        window.open('https://doi.org/10.1038/s41597-022-01307-4');
    }, []);

    const onCoverClick = useCallback((key: string) => {
        const coverIndex = coverClasses.findIndex(cover => cover.key === key);
        setCoverIndex(coverIndex);
    }, [coverIndex]);

    return <section className="hero">
        <div className="hero-content wrapper">
            <div className="typed-container">
                <h1 className="typed-title"/>
            </div>
            <div className="heading-description">A near realtime land cover dataset for our constantly changing planet.</div>
            <div className="features">
                <div><MdFilterHdr size={24}/> 10M resolution</div>
                <div><MdPublic size={24}/> Global scale</div>
                <div><MdPsychology size={24}/> AI Powered</div>
                <div><MdAssignment size={24} /> Peer Reviewed</div>
                <div><MdUpdate size={24}/> Near Realtime</div>
                <div><MdGroup size={24}/> Open Licensing</div>
            </div>
            <div className="actions">
                <UIButton size="large" onClick={onExploreClick}>Explore the data</UIButton>
                <UIButton size="large" onClick={onDiscoverClick} variant="outlined" color="secondary">Discover
                    Change</UIButton>
                <UIButton size="large" onClick={onReadPaperClick} variant="outlined" color="secondary">Read the Paper</UIButton>
            </div>
        </div>
        <div className="land-uses">
            <div className="wrapper">
                <h2>9 land use and cover types</h2>
                <ul>
                    {coverClasses.map(cover => {
                        return <li key={cover.key}
                                   onClick={() => onCoverClick(cover.key)}
                                   className={classNames({ active: coverKey === cover.key })}>
                            <img src={`/images/land-cover/land_${cover.key}.png`}/>
                            <div>{cover.title}</div>
                        </li>
                    })}
                </ul>
            </div>
        </div>
        <div className="hero-bg" style={{
            backgroundImage: `url("/images/land-cover-hero/land_${coverKey}.jpg")`
        }}/>
    </section>
}