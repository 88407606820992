import * as React from 'react';
import { useCallback } from 'react';
import './personas.scss';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import UIButton from '../controls/UIButton';

export default function Personas() {
    const data = useStaticQuery(graphql`
        query DatasetQuery {
            allPersonas {
            nodes {
              personas {
                description
                label
              }
              title
            }
          }
        }
    `).allPersonas.nodes[0];

    const onExploreClick = useCallback(() => {
        navigate('/explore');
    }, []);

    return <section className="personas">
        <div className="wrapper">
            <h2>{data.title}</h2>
            <div className="grid">
                {data.personas.map(item => <div className="persona" key={item.label}>
                    <h3>{item.label}</h3>
                    <p>{item.description}</p>
                </div>)}
            </div>
            <div className="button-container"><UIButton size="large" onClick={onExploreClick}>Explore the data</UIButton></div>
        </div>
    </section>
}