import * as React from "react"
import { useEffect } from "react"
import { Helmet } from 'react-helmet'
import '../styles/styles.scss';
import './index.scss';
import Layout from '../components/layout';
import { useSiteMetadata } from '../hooks/use-site-metadata';
import Typed from 'typed.js';
import DynamicCases from '../components/index/dynamic-cases';
import Hero from '../components/index/hero';
import Personas from '../components/index/personas';
import Partners from '../components/shared/partners';

const IndexPage = () => {
    const { title } = useSiteMetadata();

    useEffect(() => {
        new Typed('.typed-title', {
            strings: ['Dynamic World'],
            typeSpeed: 80,
            startDelay: 600,
            cursorChar: '_'
        })
    }, []);

    return (
        <main>
            <Helmet title={title}/>
            <Layout>
                <div className="index-page">
                    <Hero/>
                    <DynamicCases/>
                    <Personas/>
                    <Partners />
                </div>
            </Layout>
        </main>
    )
}

export default IndexPage
