import * as React from 'react';
import './partners.scss';

export default function Partners() {
    return <section className="partners">
        <a href="https://earthengine.google.com/" rel="noopener noreferrer" target="_blank">
            <img src="/images/logos/google.png" alt="Google" className="logo-google"/>
        </a>
        <a href="https://www.wri.org/" rel="noopener noreferrer" target="_blank">
            <img src="/images/logos/wri.png" alt="World Resources Institute" className="logo-wri"/>
        </a>
    </section>
}